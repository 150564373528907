<template>
  <div class="co-flex-col">
    <v-hover
      v-slot="{ hover }"
      :disabled="!hovered"
      close-delay="120"
    >
      <div
        class="co-flex-col co-overflow-hidden co-round3 white"
        :class="[hover ? 'co-shadow-2xl' : '']"
      >
        <template
          v-if="imgs.length"
        >
          <v-row
            dense
            justify="center"
          >
            <v-col
              v-for="(img, i) in imgs"
              :key="`al_img_${i}`"
              cols="12"
              :md="colNum"
            >
              <v-img
                :src="img.imageUrl"
                :aspect-ratio="ratio"
                height="inherit"
                @click="onClick"
              />
            </v-col>
          </v-row>
        </template>
        <template
          v-else
        >
          <v-img
            :src="bgAlbum"
            :aspect-ratio="ratio"
            height="inherit"
          />
        </template>
      </div>
    </v-hover>
    <div class="co-flex-row co-justify-between co-items-center my-2 px-1">
      <span class="co-text-sm co-font-600">
        {{ item.title }}
      </span>
      <span class="co-text-sm co-text-gray-600">
        {{ `${item.imagesTotal || 0} 张作品` }}
      </span>
    </div>
  </div>
</template>

<script>
  import app from '@/api/co.app'
  export default {
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        ratio: 1,
        colNum: 6,
        bgAlbum: app.pic.img_album_bg,
        imgs: [],
        normalElevation: 0,
        hoverElevation: 18,
        hovered: true
      }
    },
    watch: {
      item: {
        handler (val) {
          this.imgs = []
          const list = val.images || []
          let cnt = list.length
          if (cnt > 4) {
            cnt = 4
          }

          for (let i = 0; i < cnt; i++) {
            this.imgs.push(list[i])
          }

          cnt = this.imgs.length
          if (cnt === 1) {
            this.colNum = 12
            this.ratio = 1
          } else if (cnt === 2) {
            this.colNum = 12
            this.ratio = 2
          } else {
            this.colNum = 6
            this.ratio = 1
          }
        },
        immediate: true
      }
    },
    methods: {
      onClick () {
        this.$emit('click', this.item)
      }
    }
  }
</script>
