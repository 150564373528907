<template>
  <div
    class="co-flex-col pa-4"
  >
    <v-row>
      <template
        v-if="items.length"
      >
        <v-col
          v-for="(item, i) in items"
          :key="`ab_${i}`"
          cols="12"
          md="3"
        >
          <album-card
            :item="item"
            @click="toAlbum"
          />
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
        >
          <div class="co-flex-col co-justify-center co-items-center pa-6">
            <span
              class="co-text-md co-text-gray-500"
            >
              当前用户还没有影集
            </span>
          </div>
        </v-col>
      </template>
    </v-row>

    <!-- <div
      v-if="pagination.length"
      class="d-flex flex-row mt-6 mb-8"
    >
      <v-pagination
        v-model="pagination.current"
        :length="pagination.length"
        :total-visible="pagination.totalVisible"
        @input="changePagination($event)"
      />
    </div> -->
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  // import web from '@/api/web/co.web'

  import mixPreset from '@/pages/mixins/my/mix.my.preset'
  import AlbumCard from '@/pages/sections/my/AlbumCard.vue'

  const parts = mixPreset.Parts
  const names = mixPreset.Names
  const part = parts[names.ALBUMS]

  export default {
    components: {
      AlbumCard
    },
    props: {
      userId: {
        type: String,
        default: ''
      },
    },
    data () {
      return {
        name: part.name,
        label: part.label,
        title: part.title,
        total: 0,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          pageSize: 9,
          totalVisible: 7
        },
      }
    },
    watch: {
      userId: {
        handler (val) {
          if (val) {
            this.getAlbums(true)
          }
        },
        immediate: true
      }
    },
    created () {
      // this.getAlbums()
    },
    methods: {
      toAlbum (ev) {
        const params = {
          albumId: ev.albumId,
          title: ev.title
        }

        api.page.navigate(this, 'album', params)
      },
      getAlbums (userId) {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            const data = res.data || {}
            me.total = data.total
            me.title = `${me.label} ${me.total}`
            app.emit(app.event.USER_ALBUMS_GET, {
              name: me.name,
              title: me.title,
              label: me.label,
              total: me.total
            })

            const pagination = me.pagination
            const pageSize = parseInt(pagination.pageSize) || 9
            pagination.length = Math.ceil(me.total / pageSize)

            me.items = data.records || []
          }
        }

        api.httpx.pull({
          url: `${api.url.web_album_albums_public}/${userId}`,
          data: {
            userId,
            currentPage: this.pagination.current,
            pageSize: this.pagination.pageSize,
          },
          executed
        })
      }
    }
  }
</script>
